import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({ 
  colorLogo: {
    background: 'linear-gradient(to right bottom, #36EAEF, #6B0AC9)'
  },
  landingContainer: {
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  judulLanding: {
    fontSize: "3rem",
  },
  goDown: {
    color: "#5AFF3D",
    fontSize: "2em",
  },
  rootKontenSurvei: {
    minHeight: "100vh",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
    display: 'flex'
  },
  rootCard: {
    maxWidth: 645,
    background: "rgba(0,0,0,0.5)",
    margin: "20px",
  },
  media: {
    height: 440,
  },
  title: {
    fontFamily: "Nunito",
    fontWeight: "bold",
    fontSize: "2rem",
    color: "#fff",
  },
  desc: {
    fontFamily: "Nunito",
    fontSize: "1.1rem",
    color: "#ddd",
  },
}));
