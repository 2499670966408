import React from 'react'
// import AppLayout from './components/Layouts/AppLayout'
import LandingLayout from './components/Layouts/LandingLayout'

// Komponen
// import FormSurveyPage from './components/FormSurveyPage'
import LandingSurveyPage from './components/LandingSurveyPage'

function App() {
  return (
    <>
      <LandingLayout>
        <LandingSurveyPage />
      </LandingLayout>
    </>
  );
}

export default App;
