import React, { useEffect, useState } from 'react'
import { Collapse, IconButton, Button } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { styled } from '@material-ui/core/styles'
import { Link as Scroll } from 'react-scroll'
import useStyles from './styles'
import KontenSurveiKlik from './KontenSurveiKlik'
// import myLogo from '../../assets/img/logo192.png'

function LandingSurveyPage() {

    const classes = useStyles();
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        setChecked(true);
    }, []);


    const MyAplikasi = styled(Button)({
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        border: 0,
        borderRadius: 3,
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        color: 'white',
        height: 38,
        padding: '0 20px',
    });

    const RsupPersahabatan = styled(Button)({
        background: 'linear-gradient(45deg, #4396D2 30%, #3AAF4B 90%)',
        border: 0,
        borderRadius: 3,
        boxShadow: '0 3px 5px 2px rgba(67, 105, 135, .3)',
        color: 'white',
        height: 48,
        padding: '0 20px',
        fontFamily: 'Metropolis',
        fontSize: '.8em'
    });

    return (
        <React.Fragment>
            <div className={classes.landingContainer} id="header">
                <Collapse 
                    in={checked}
                    style={{ transformOrigin: '0 0 0' }}
                    {...(checked ? { timeout: 1000 } : {})}
                >
                    <h1 className={classes.judulLanding}>
                        Selamat Datang <br /> di <Scroll to="klik-konten-survei" smooth={true}><MyAplikasi>Aplikasi Survei</MyAplikasi></Scroll> <RsupPersahabatan>{/* <Icon> <img src={myLogo} height={25} width={25}/></Icon> */}RSUP Persahabatan.</RsupPersahabatan>
                    </h1>
                    <Scroll to="klik-konten-survei" smooth={true}>
                        <IconButton>
                            <ExpandMoreIcon className={classes.goDown} />
                        </IconButton>
                    </Scroll>
                </Collapse>
            </div>

            <KontenSurveiKlik />

        </React.Fragment>
   
    )
}

export default LandingSurveyPage
