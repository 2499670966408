import React from 'react'
import { AppBar, Toolbar, Typography } from '@material-ui/core'
import useStyles from './styles'

import logo from '../../assets/img/logo-text.png'


export default function Header() {
    const classes = useStyles();
    return (
        <AppBar position="absolute" color="default" className={classes.appBar}>
            <Toolbar varian="h6" color="inherit" className={classes.appBarWrapper}>
                <Typography><img src={logo} alt="RSUP PERSAHABATAN" className={classes.logoAtas} /></Typography>
            </Toolbar>
        </AppBar>
    )
}
