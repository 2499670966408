import React from "react";
import {
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
  Collapse,
  CardMedia,
  Link
} from "@material-ui/core";

import useStyles from "./styles";

function KontenSurvei({ surveiData, checked }) { console.log(surveiData)

  const classes = useStyles();

  const url =
  process.env.NODE_ENV === "development"
    ? `https://surveytbc.vercel.app/`
    : `https://survei.rsuppersahabatan.co.id/`;

  return (
    <React.Fragment>
        <Collapse in={checked} {...(checked ? { timeout: 1000 } : {})}>
            <Card className={classes.rootCard}>
              <CardMedia
                className={classes.media}
                image={(surveiData.imageUrl == null || '' || 'undefined') ? process.env.PUBLIC_URL + '/island2.jpg' : surveiData.imageUrl}
                title="Contemplative Reptile"
              />
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="h1"
                  className={classes.title}
                >
                  {surveiData.title}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  className={classes.desc}
                >
                {surveiData.description}            
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="medium" color="primary">
                  <Link href={`${url}${surveiData.kode}`} target="_blank" rel="noreferrer" color="inherit">
                    Klik Mulai!                
                  </Link>
                </Button>
              </CardActions>
            </Card>

        </Collapse>
    </React.Fragment>
  );
}

export default KontenSurvei;
