import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import backendAPI from '../../../api/website'

// Ulititas
import { sleep } from '../../../hooks/sleep'

// source: https://github.com/sanderdebr/redux-crud-tutorial/blob/master/src/features/users/usersSlice.js dan https://stackoverflow.com/a/68452785
export const fetchSurvei = createAsyncThunk("survei/fetchSurvei", async () => {
    const res = await backendAPI.get("/getSurveiOnline");
    await sleep(1000);
    return res.data.results; // source: https://stackoverflow.com/q/66753905
})

const surveiSlice = createSlice({
    name: "survei",
    initialState:{
        entitas: [],
        loading: false
    },
    reducers: {},
    extraReducers: {
        [fetchSurvei.pending]: (state, action) => {
            state.loading = true;
        },
        [fetchSurvei.fulfilled]: (state, action) => { //console.log(action);
            state.loading = false;
            state.entitas = action.payload;
        },
        [fetchSurvei.rejected]: (state, action) => {
            state.loading = false;
        }
    }
})


// export const { } = surveiSlice.actions;
export default surveiSlice.reducer;
