import {
    createTheme,
    responsiveFontSizes,
    makeStyles,
    withStyles
  } from '@material-ui/core/styles';
  import { cyan } from '@material-ui/core/colors';
  import TableCell from '@material-ui/core/TableCell';
  import TableRow from '@material-ui/core/TableRow';
  
  let theme = createTheme({
    palette: {
      type: 'light',
      primary: cyan,
      secondary: cyan
    }
  });
  
  theme = responsiveFontSizes(theme);
  
  const useStyle = makeStyles(() => ({
    root: {
      backgroundImage: `url(${process.env.PUBLIC_URL + "/landing.jpg"})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
  }));

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell)

  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);
  
  export { theme, useStyle, StyledTableCell,StyledTableRow };