import axios from "axios";

const url =
  process.env.NODE_ENV === "development"
    ? `http://localhost/api/survei/`
    : `https://rsuppersahabatan.co.id/apis/survei/`;

let token = process.env.REACT_APP_API;

const headers = { Accept: 'application/json', 'Content-Type': 'application/x-www-form-urlencoded', Authorization: `Persahabatan ${token}` };

export default axios.create({
  baseURL: url,
  timeout: 50000,
  headers: headers
});