import React, { useEffect } from 'react';
import KontenSurvei from './KontenSurvei';
import useWindowPosition from '../../hooks/useWindowPosition';
import useStyles from "./styles";

// Redux
import { fetchSurvei } from '../../stores/features/survei/surveiSlice'
import { useDispatch, useSelector } from "react-redux"


const KontenSurveiKlik = () => {

    const dispatch = useDispatch();
    // const { entitas: survei } = useSelector(fetchSurvei);
    const survei = useSelector((state) => state.survei.entitas); 

 
    useEffect(() => {
        dispatch(fetchSurvei());
    }, [dispatch])

    const classes = useStyles();
    const checked = useWindowPosition('header');
    return (
        <div className={classes.rootKontenSurvei} id="klik-konten-survei">
            {/* {console.log('dataAPI:', survei,`\n`,'dataStatic:', places)} */}
            
            {survei.map((item,id) => <KontenSurvei key={id} surveiData={item} checked={checked} />)}

        </div>
    );
}

export default KontenSurveiKlik