import React from 'react'
import { CssBaseline } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/core/styles'
import { theme, useStyle } from './stylesLanding'

// komponen
import Header from '../Header'
import Footer from '../Footer'

export default function LandingLayout(props) {
    const { children } = props;
    const classes = useStyle()
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Header />
            <div className={classes.root}>
                {children}
            </div>
            <Footer />
        </ThemeProvider>
    )
}
